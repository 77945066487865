<template>
  <div v-html="topBarContent"></div>
  <header
    :class="{ sticky: isSticky }"
    style="background-color: rgb(247 239 241 / 64%)"
  >
    <!-- v-click-outside="onClickOutside"> -->

    <div class="container">
      <nav
        class="navbar navbar-expand-lg px-sm-3 px-0 navbar-fixed-top mb-0"
        style="z-index: 1 !important"
      >
        <a :href="portalUrl">
          <v-img
            :src="portalLogo"
            class="portal_logo"
            @click="$router.push('/')"
          />
        </a>
        <v-spacer></v-spacer>

        <div
          class="d-flex align-center me-sm-3 me-1"
          v-if="headerMenus.includes('phone')"
        >
          <a
            :href="`tel:${phonePortal}`"
            class="d-flex align-center"
            style="color: black"
          >
            <v-icon size="20" class="me-1" color="black">mdi-phone</v-icon>
            <span class="d-sm-block d-none">{{ phonePortal }}</span>
          </a>
        </div>

        <div
          class="d-flex align-center me-sm-3 me-1"
          v-if="headerMenus.includes('country')"
        >
          <v-menu v-model="isMenuCurrency" location="bottom">
            <template v-slot:activator="{ props }">
              <v-card
                class="shadow-none bg-transparent d-flex"
                v-bind="props"
                style="cursor: pointer"
              >
                <div class="d-flex align-center">
                  <div>
                    <v-img
                      :src="display_flagLogo"
                      width="30px"
                      height="30px"
                      style="border-radius: 50%"
                    />
                  </div>

                  <span v-if="currentCountry" class="ps-1 currency-text f-size-15 fw-400 text-dark">
                    {{ currentCountry.currency }}
                  </span>
                </div>
                <v-list-item-icon
                  v-if="countryOptions.length > 1"
                  class="d-sm-block d-none"
                >
                  <span>
                    <v-icon size="30" :class="{ 'rotate-icon': isMenuCurrency }"
                      >mdi-menu-down</v-icon
                    >
                  </span>
                </v-list-item-icon>
              </v-card>
            </template>
            <v-list style="cursor: pointer" v-if="countryOptions.length > 1">
              <div v-for="(country, index) in filteredCountryOptions" :key="index">
                <v-list-item class="hoverList" v-if="country.country_code !== countyIsocode">
                    {{ console.log(countryOptions, "country.flag_logo") }}
                    <div>
                      <a :href="userqueryIso !== country.country_code ? country.portal_url : `${country.portal_url}/?ref=web&redirect=false`" style="text-decoration: none;">
                        <div class="d-flex align-center">
                          <div>
                            <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius: 50%" />
                          </div>
                          <span class="ps-1 f-size-15 text-dark" >{{ country.currency }}</span>
                        </div>
                      </a>
                    </div>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </div>

        <div
          class="d-flex align-center me-sm-3 me-1"
          v-if="headerMenus.includes('language')"
        >
          <v-menu location="bottom" v-model="isMenulanguage">
            <template v-slot:activator="{ props }">
              <v-card
                class="shadow-none bg-transparent"
                v-bind="props"
                style="cursor: pointer"
              >
                <div class="d-flex align-center">
                  <div class="currency-text">
                    <v-img
                      :src="require('@/assets/img/icon/language_icon.png')"
                      width="22px"
                    />
                  </div>

                  {{
                    console.log(
                      currencyData.display,
                      "currencyDatadisplaycurrencyDatadisplay"
                    )
                  }}
                  <span
                    class="f-size-15 fw-400 text-dark ps-1 text-uppercase"
                    >{{ displayLanguage }}</span
                  >
                  <v-list-item-icon
                    v-if="languageOptions.length > 1"
                    class="d-sm-block d-none"
                  >
                    <span>
                      <v-icon
                        size="30"
                        :class="{ 'rotate-icon': isMenulanguage }"
                        >mdi-menu-down</v-icon
                      >
                    </span>
                  </v-list-item-icon>
                </div>
              </v-card>
            </template>
            <v-list
              style="cursor: pointer; display: flex; flex-direction: row"
              v-if="languageOptions.length > 1"
            >
              <v-list-item
                class="hoverList"
                v-for="(lang, index) in dropdownLanguages"
                :key="index"
              >
                <div
                  class="d-flex align-center"
                  @click="changeLocale(lang.code)"
                >
                  <span class="ps-1" style="font-size: 15px; color: black"
                    >{{ lang.name }} -
                    <span class="text-uppercase">{{ lang.code }}</span></span
                  >
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="container-fluid setSize px-0">
          <div
            class="collapse navbar-collapse p-0 m-auto"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav my-2 my-lg-0 mx-3">
              <li
                class="nav-item dropdown d-flex justify-end align-center me-sm-3"
                v-if="headerMenus.includes('email')"
              >
                <!-- <v-menu location="bottom"> -->
                <v-menu v-model="isMenuHelp" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-card
                      class="shadow-none bg-transparent"
                      v-bind="props"
                      style="cursor: pointer"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/helpbox.png')"
                            width="25px"
                          />
                        </div>
                        <!-- <span class="ps-1 currency-text" style="font-size: 15px;font-weight: 400;color:black">{{ $t('homePageContent.welcomeMsg.text1') }}</span> -->
                        <span
                          class="ps-1"
                          style="
                            font-size: 15px;
                            font-weight: 400;
                            color: black;
                          "
                          >{{ $t("headerContents.helpSupport") }}</span
                        >

                        {{ console.log(this.$i18n.locale, "helpSupport...1") }}
                        <v-list-item-icon>
                          <span style="cursor: pointer">
                            <v-icon
                              size="30"
                              :class="{ 'rotate-icon': isMenuHelp }"
                              >mdi-menu-down</v-icon
                            >
                          </span>
                        </v-list-item-icon>
                      </div>
                    </v-card>
                  </template>
                  <v-list style="cursor: pointer">
                    <v-list-item class="hoverList">
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/email_icon.png')"
                            width="18px"
                          />
                        </div>
                        <a
                          :href="'mailto:' + emailPortal"
                          class="text-decoration-none"
                        >
                          <span
                            class="ps-1"
                            style="font-size: 15px; color: black"
                            >{{ emailPortal }}</span
                          >
                        </a>
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      @click="toggleTawkToChat()"
                      v-if="this.headerMenus.includes('chatsupportlink')"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/chat_icon.png')"
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.chatSupport") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" to="/viewBooking">
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/myBooking_icon.png')"
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >View My Trip</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" to="/contactPage">
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/reqform_icon.png')"
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.requestForm") }}</span
                        >
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>

              <li
                class="nav-item dropdown d-flex justify-end align-center"
                v-if="!headerMenus.includes('accounts')"
              >
                <v-menu v-model="isMenuAccount" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-card
                      class="shadow-none bg-transparent"
                      v-bind="props"
                      style="cursor: pointer"
                    >
                      <div class="d-flex align-center">
                        <!-- <div>
                          <span
                            ><i class="bi bi-person-circle f-size-18"></i
                          ></span>
                        </div> -->
                        <span class="accountLabel1 d-flex align-center">
                          <v-img
                            class="accountLabel1"
                            :src="require('@/assets/img/icon/account_icon.png')"
                            width="28px"
                          />
                          <span
                            class="ps-1 f-size-15 fw-400 m-auto"
                            v-if="this.userName"
                            style="color: black"
                            >{{ this.userName }}</span
                          >
                        </span>
                        <span class="accountLabel2">
                          <span
                            ><i class="bi bi-person-circle f-size-18 me-2"></i
                          ></span>
                          <span class="accountText f-size-15 fw-400 m-auto">{{
                            this.userName
                              ? this.userName
                              : $t("headerContents.account")
                          }}</span>
                        </span>

                        <v-list-item-icon>
                          <span style="cursor: pointer">
                            <v-icon
                              size="30"
                              :class="{ 'rotate-icon': isMenuAccount }"
                              >mdi-menu-down</v-icon
                            >
                          </span>
                        </v-list-item-icon>
                      </div>
                    </v-card>

                    <!-- <v-list-item-icon class="accountLabel2">
                      <span>
                        <v-icon size="30" :class="{ 'rotate-icon': isMenuAccount }">mdi-menu-down</v-icon>
                      </span>
                    </v-list-item-icon> -->
                  </template>
                  <v-list style="cursor: pointer">
                    <v-list-item
                      class="hoverList"
                      to="/myBooking"
                      v-if="this.userName"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/myprofile_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.myBookings") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      v-if="!this.userName"
                      @click="showLoginForm()"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/userlogin_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.login") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      v-if="!this.userName"
                      @click="showRegisterForm()"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/userregister_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.register") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      to="/myprofile"
                      v-if="this.userName"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/myBooking_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >My Profile</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      to="/viewBooking"
                      v-if="this.headerMenus.includes('viewBooking')"
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/viewBooking_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.viewBooking") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item
                      class="hoverList"
                      to="/dashboard"
                      v-if="
                        this.userName &&
                        this.headerMenus.includes('viewbooking')
                      "
                    >
                      <div class="d-flex align-center">
                        <div>
                          <v-img
                            :src="
                              require('@/assets/img/icon/myBooking_icon.png')
                            "
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.myBookings") }}</span
                        >
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" v-if="this.userName">
                      <div class="d-flex align-center" @click="deleteItem()">
                        <div>
                          <v-img
                            :src="require('@/assets/img/icon/logout_Icon.png')"
                            width="18px"
                          />
                        </div>
                        <span
                          class="ps-1"
                          style="font-size: 15px; color: black"
                          >{{ $t("headerContents.logOut") }}</span
                        >
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      loginform: false,
      registerform: false,
      selectedCountry: null,
      isSticky: false,
      conDetails: true,
      menuData: "",

      isMenuCurrency: false,
      isMenulanguage: false,
      isMenuHelp: false,
      isMenuAccount: false,

      PortMail: "",
      PortContact: "",

      portCurrency: "EN US$",

      hostName: "",
      portalUrl: "",
      userqueryIso:"",

      userDetails: true,
      userProfile: false,
      userEmail: "",
      userName: "",
      portalLogo: "",
      emailPortal: "",
      phonePortal: "",
      countryOptions: [],
      languageOptions: [],
      languagePortal: "",
      currencyPortal: "",
      countyIsocode: "",
      siteContentData: "",
      display_flagLogo: "",
      topBarContent: "",
      topBarApi: "",

      displayLanguage: "",
      dropdownLanguages: [],

      headerMenus: [],
      changedLang: "",
    };
  },
  methods: {
    showLoginForm() {
      this.$router.push("/login");
      this.loginform = true;
      this.registerform = false;
    },
    showRegisterForm() {
      this.$router.push("/register");
      this.registerform = true;
      this.loginform = false;
    },

    switchToLogin() {
      this.loginform = true;
      this.registerform = false;
    },
    switchToRegister() {
      this.registerform = true;
      this.loginform = false;
    },

    // getLanguage(getLang){
    //   console.log(getLang,'getLanggetLanggetLanggetLang')
    //   let data = getLang !== languages.display
    //   return data ? true : false
    // },

    // displayLanguage(){
    //   return languageOptions[0].code.toUpperCase()
    // },

    changeLocale(locale) {
      const reloadLang = setInterval(() => {
        // location.reload();
      }, 150);

      // this.languages.forEach(v=>{
      // console.log(v.language.split('-')[1],'v.language.split('-')[1]v.language.split('-')[1]')
      // if(v.language.split('-')[1] == locale){
      // const countryCode = this.countryOptions[0].country_code;

      if (reloadLang) {
        console.log(reloadLang, "reloadLangreloadLang");
        const newLocale = `${this.countyIsocode}-${locale}`;
        console.log("Changing locale to", newLocale);
        // this.$i18n.locale = newLocale;

        this.changedLang = newLocale;
        this.$emit("localChanged", this.changedLang);
        localStorage.setItem("defaultlang", newLocale);
        // this.$i18n.fallbackLocale = v.language;
        console.log("Current locale:", this.$i18n);
        // }
        // })
        this.displayLanguage = locale;
        this.updateDropdownLanguages();

        // this.localeLanguage();
      }
    },

    updateDropdownLanguages() {
      console.log(
        this.languageOptions,
        "languageOptionslanguage",
        this.displayLanguage
      );
      this.dropdownLanguages = this.languageOptions.filter((lang) => {
        if (lang.code !== this.displayLanguage) {
          return lang.code;
        }
      });
      console.log(this.displayLanguage, "dropdownLanguagesdropdownLanguages");
      console.log(this.$i18n.locale, "getegeteegtegete....1");
    },

    displayedLang() {
      let getlocalIso = this.$i18n.locale;

      let getsetlang = getlocalIso.split("-")[1];
      this.displayLanguage = getsetlang;
      // let datalang = this.localeLanguage();
      // let getlocalIso = datalang;
      // this.$i18n.locale = datalang;
      // let getlocalIso = this.$i18n.locale;
      console.log(this.displayLanguage, "i18ni18ni18ni18ni18n");
      this.updateDropdownLanguages();
      console.log(this.$i18n.locale, "getegeteegtegete....2");
    },

    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";

      if (storedLocale) {
        getlocalIso = storedLocale.split("-")[0];
        getlocalLang = storedLocale.split("-")[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split("-")[0];
        getsetlang = storedSetLang.split("-")[1];
      }

      if (storedSetLang && storedLocale) {
        console.log("Check...1");
        if (storedLocale == storedSetLang) {
          console.log("Check...2");

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        } else if (storedLocale !== storedSetLang) {
          console.log(
            getlocalIso,
            getsetIso,
            "Check...3",
            getlocalLang,
            getsetlang
          );
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {
            localStorage.removeItem("defaultlang");
            console.log("Check...4");
            return storedSetLang;
            // this.i18n.locale = storedSetLang;
          } else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log("Check...5");

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      } else if (storedSetLang) {
        console.log("Check...6");

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      } else if (storedLocale) {
        console.log("Check...7");

        return storedLocale;
        // this.i18n.locale = storedLocale;
      } else {
        console.log("Check...8");

        return `${this.countyIsocode}-${this.getLanguage[0].code}`;
        // this.i18n.locale = `${this.countyIsocode}-${this.getLanguage[0].code}`;
      }
    },

    toggleDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = dropdownName;
      }
    },

    toggleTawkToChat() {
      if (window.Tawk_API) {
        window.Tawk_API.toggle();
      }
    },

    portalLink(countryOptions) {
      return countryOptions[0].portal_url;
    },
    portallogo(countryOptions) {
      return countryOptions[0].flag_logo;
    },
    // portalLanguage(countryOptions) {
    //   return countryOptions[0].languages
    // },
    // portalcurrency(currencyData) {
    //   console.log(currencyData,'currencyDatacu')
    //   return currencyData.display
    // },

    handleScroll() {
      this.isSticky = window.scrollY > 90;
    },
    signIn() {
      // this.loginform = true;
      this.loginform = !this.loginform;
      // this.registerform = false;
    },
    signUp() {
      // this.registerform = true;
      this.registerform = true;
      // this.registerform = false;
    },

    // onClickOutside() {
    //   this.loginform = false
    //   // console.log(this.loginform, "666666")
    // },

    closeLogin() {
      this.loginform = false;
      console.log(this.loginform, "4444444");
    },
    // register() {
    //   this.registerform = !this.registerform; // Toggle the value of registerform
    //   this.loginform = false; // Close the login form if open
    // },
    closeRegister() {
      this.registerform = false;
      console.log(this.registerform, "registerformregisterform");
    },

    getUser() {
      let userData = JSON.parse(localStorage.getItem("value"));
      // console.log(userData, "user....");
      if (userData) {
        this.userDetails = false;
        this.userProfile = true;
      } else {
        this.userDetails = true;
        this.userProfile = false;
      }
    },
    booking() {
      this.$router.push("/dashboard");
    },
    deleteItem() {
      localStorage.removeItem("value");
      localStorage.removeItem("loginuser");
      setInterval(() => {
        location.reload();
      }, 500);
      this.userDetails = true;
      this.userProfile = false;
      this.$router.push("/");
    },

    getLoginDatas() {
      JSON.parse(localStorage.getItem("loginDatas"));
      // console.log(userItems, "loginddddd......");
      // this.user.email = userItems.userEmail
      // this.user.email = userItems.userPass
    },
    getlogo(data) {
      console.log(data, "duifuisddsddjik");
    },
    getTopBar() {
      axios
        .get(this.topBarApi.url, {
          headers: {
            "Content-Type": this.topBarApi.type,
          },
        })
        .then((response) => {
          this.topBarContent = response.data;
          // console.log(response.data, "topBarContenttopBarContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },

    getConfigFunc() {
      // Parse the decrypted data as JSON
      const getConfigData = getConfigDataFromLocalStorage();
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      console.log(getConfigData, "head...1");
      // let getConfigData = this.$store.state.data

      if (getConfigData) {
        console.log(getConfigData, "head...2");

        this.menuData =
          getConfigData.payload.portal_configuration.menus.enabled.header;

        let Menus = "";
        // Menus = getConfigData.payload.portal_configuration.menus.enabled.header;
        Menus = "country, language, email, phone";
        // console.log(this.menuData.includes('viewbookings'),'MenusMenus3333')
        this.headerMenus = Menus.split(", ");

        // console.log(this.headerMenus, "MenusMenusMenusMenus")

        // getConfigData.payload.portal_configuration.siteTitle
        // this.menuEmail = this.Menus.header.email
        // this.menuPhone = this.Menus.header.phone
        // console.log(this.menuEmail,"pmpmpmpmpm...1")
        // console.log(this.menuPhone,"pmpmpmpmpm....2")

        this.topBarApi =
          getConfigData.payload.portal_configuration.content_data.topbar;
        if (this.topBarApi) {
          this.getTopBar();
        }

        this.portalUrl = getConfigData.payload.portal_configuration.portal_url;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.currencyData = getConfigData.payload.portal_configuration.currency;
        this.countyIsocode = getConfigData.payload.portal_configuration.country.iso_code;

        // this.portalLanguage = 'ENG'
        this.portalLanguage =
          getConfigData.payload.portal_configuration.language;

        this.countryOptions =
          getConfigData.payload.portal_configuration.country_options;
        console.log(this.countryOptions, "countryOptionssss");
        this.countryOptions.forEach((v, i) => {
          console.log(
            v.currency,
            v.flag_logo,
            i,
            this.currencyData.display,
            v.default_domain,
            i,
            "checkcheckcheckcheck"
          );
          if (v.currency == this.currencyData.display) {
            this.display_flagLogo = v.flag_logo;
            this.currencyData.display = v.currency;
            console.log(
              this.currencyData.display,
              this.display_flagLogo,
              v.currency,
              "currencyDatacurrencyData...1"
            );
          }
        });

        this.languageOptions =
          getConfigData.payload.portal_configuration.languages;
        // this.languageOptions = [
        //   {
        //     code: "en",
        //     name: "English",
        //   },
        // ];
        console.log(this.languageOptions, "languageOptionslanguage...1");

        this.displayLanguage = this.languageOptions[0].code;

        // this.languageData = getConfigData.payload.portal_configuration.language_data;
        // this.getlangaugeUrl();

        // console.log(this.languageData,'siteContentDatasiteContentDatasiteContentData')

        this.siteContentData =
          getConfigData.payload.portal_configuration.site_content_data;

        this.updateDropdownLanguages();
      }
    },

    decyptedquery(){
      const getrouteiso = this.$route.query ? (this.$route.query.usercountry || null ) : null;
      let secretqueryKey = "secretquery";
      if(getrouteiso){     
        const decodedEncrypted = getrouteiso ? atob(getrouteiso) : null;
        const decrypted = CryptoJS.AES.decrypt(decodedEncrypted, secretqueryKey).toString(CryptoJS.enc.Utf8);
        let usercountryIso = decrypted ? JSON.parse(decrypted) : null;
        this.userqueryIso = usercountryIso;
      }
    }
  },

  watch: {
    "$i18n.locale": function () {
      this.displayedLang();
    },
  },

  computed:{
    currentCountry() {
      return this.countryOptions.find((country) => country.country_code === this.countyIsocode) || null;
    },
    filteredCountryOptions() {
      return this.countryOptions.filter((country) => country.country_code !== this.countyIsocode);
    },
  },

  created() {
    let $useresult = JSON.parse(localStorage.getItem("loginuser"));
    // console.log($useresult, '$useresult $useresult ')
    if ($useresult) {
      this.userEmail = $useresult.user.email;
      this.userName = $useresult.user.first_name;
      console.log($useresult, "$useresult$useresult");
      console.log(this.userEmail, this.userName, "adsasfasdas");
    }
    this.getConfigFunc();

    // this.localeLanguage();
    this.displayedLang();
    // this.localeLanguage();
    console.log(this.$i18n.locale, "getegeteegtegete");
  },

  mounted() {
    // setTimeout(() => {
    // this.getConfigFunc();
    // }, 500)

    window.addEventListener("scroll", this.handleScroll);
    this.getUser();
    this.getLoginDatas();
    this.decyptedquery();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.f-size-15 {
  font-size: 15px;
}
.f-size-14 {
  font-size: 14px;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}

/* navbar */
.logo {
  width: 180px;
}

.portal_logo {
  width: 115px;
  height: 55px;
  cursor: pointer;
}

.navbar {
  z-index: 9 !important;
}

.navbar-fixed-top {
  z-index: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f4f5 !important;
  z-index: 999;
  animation: fadeInDown 0.6s ease-in-out 0s 1 normal none running;
  transition: 0.6s;
  box-shadow: 0px 0px 20px 0px black;

  /* Add any other styles you need for the sticky header */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-18);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-400);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #FF5400;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.bgimg {
  /* background-image: url("@/assets/CA.svg"); */
  background-repeat: no-repeat;
  background-position: center;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  /* color: white; */
}

.btn-active {
  background-color: blueviolet;
  /* color: white; */
}

.v-checkbox .v-input__details {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-toggler {
  border: none !important;
}

.dropdown-toggle {
  width: 50px;
  height: 30px;
  white-space: nowrap;
  border: 1px solid !important;
}

/* .v-app-bar {
  height: 100px;
  background-image: url('../assets/flight.jpg');
  background-size: 100% 100%;
} */

/* .btn-box,
.pipe {
  color: white !important;
} */

.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

.contact-box span {
  font-size: 15px;
}

.hoverList:hover {
  background-color: lightgrey;
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking:hover {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking-active {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.rotate {
  animation: spin 0.5s forwards;
}

.navbar .container-fluid.setSize {
  width: fit-content !important;
  /* width: auto; */
}

.contact-section-mobile1 {
  display: none !important;
}

.accountLabel1 {
  display: block;
}

.accountLabel2 {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

/* .currency-text {
    display: block;
  } */

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .contact-section-mobile {
    display: none !important;
  }

  .contact-section-large {
    display: flex !important;
  }

  .contact-section-collapse {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .navbar .container-fluid.setSize {
    width: 100% !important;
    top: 100%;
    right: 0;
    position: absolute;
    background: white;
    width: fit-content !important;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 1px 2px 0px black;
    z-index: 999;
  }

  .contact-section-mobile {
    display: flex !important;
  }

  .contact-section-large {
    display: none !important;
  }

  .contact-section-collapse {
    display: block !important;
  }
}

@media only screen and (max-width: 480px) {
  .myBooking {
    display: none !important;
  }

  .portal_logo {
    width: 150px;
    height: 55px;
    cursor: pointer;
  }

  .dropdown-toggle {
    width: 40px;
    height: 28px;
    padding: 0 !important;
  }

  /* .navbar .container-fluid.setSize {
    width: 100% !important;
    top: 100%;
    right: 0;
    position: absolute;
    background: white;
    width: fit-content !important;
    border-radius: 0px 0px 6px 6px;
  } */

  .iconDiv {
    border: 1px solid black;
  }

  .currency-text {
    display: none;
  }

  .accountLabel1 {
    display: none !important;
  }

  .accountLabel2 {
    display: block !important;
  }
}

@media screen and (max-width: 430px) {
  .contact-section-mobile1 {
    display: block !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .dropdown-toggle {
    border: 0px !important;
  }

  /* .emailhover:hover{
  background-color:aquamarine;
} */
}

.dropdown-toggle {
  border: 0px !important;
}
</style>

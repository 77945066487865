import CryptoJS from 'crypto-js';

const secretKey = 'portal_config_datas';

export const setConfigDataToLocalStorage = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  console.log('portalEncryteDecryptddata....1');
  
  localStorage.setItem("portalData", encryptedData);
};

export const getConfigDataFromLocalStorage = () => {
  const encryptedData = localStorage.getItem('portalData');
  console.log('portalEncryteDecryptddata....2');
  if (encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
  
  return null;
};
<template>
    <footer>
        <div style="background-color:#26225e; color: white">
        <div class="container">
        <div class="row p-3 gy-3 m-0">
          <div class="col-md-3 col-12 m-0">
            <div class="footer-col-1" align="center">
              <v-img :src="portalLogo" style="height: 55px; filter: brightness(0) invert(1);" />
  
              <div class="mt-2 ms-2" v-if="social_media">
                <a v-for="(data,index) of social_media" :key="index" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                  :href="data.url" role="button" data-mdb-ripple-color="dark">
                  <v-icon color="white" size="22"> {{ data.icon_class }} </v-icon>
                </a>
              </div>
              <!-- <div class="mt-2 ms-2" v-if="!social_media">
                <a v-if="footMenu.includes('fb')" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                  :href="getFacebook" role="button" data-mdb-ripple-color="dark">
                  <v-icon color="white" size="22"> mdi-facebook </v-icon>
                </a>
  
                <a v-if="footMenu.includes('insta')" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                  :href="getInstagram" role="button" data-mdb-ripple-color="dark">
                  <v-icon color="white" size="22"> mdi-instagram </v-icon>
                </a>
  
                <a v-if="footMenu.includes('threads')" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 mt-1 social-icons"
                  :href="getThreads" role="button" data-mdb-ripple-color="dark">
                  <i class="bi bi-threads"></i>
                </a>
                 <a v-if="footMenu.includes('linkedin')" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                  :href="getLinkedIn" role="button" data-mdb-ripple-color="dark">
                  <v-icon color="white" size="22"> mdi-linkedin </v-icon>
                </a>
                <a v-if="footMenu.includes('youtube')" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                  :href="getYoutube" role="button" data-mdb-ripple-color="dark">
                  <v-icon color="white" size="22"> mdi-youtube </v-icon>
                </a>
              </div> -->
            </div>
          </div>
  
          <div class="col-md-2 col-6 text-lg-start text-center m-0" v-if="footMenu.includes('aboutus')">
            <h6 style="margin-top: 30px">
              <a href="/aboutUs" target="_blank" style="color: white; text-decoration: none">{{ $t("footerContents.aboutUs") }}</a>
            </h6>
            <!-- <h6>Company</h6>
                 <ul class="p-0 fs-14">
                   <li>About us</li>
                   <li>Destination</li>
                   <li>Packages</li>
                   <li>Contact us</li>
                 </ul> -->
          </div>
          <div class="col-md-2 col-6 text-lg-start text-center m-0" v-if="footMenu.includes('contactus')">
            <h6 style="margin-top: 30px">
              <a href="/contactPage" target="_blank" style="color: white; text-decoration: none">
                {{ $t("footerContents.contactUs") }}</a>
            </h6>
            <!-- <h6>Help</h6>
                 <ul class="p-0 fs-14">
                   <li>Help/Faqs</li>
                   <li>Cancel Your Flight</li>
                   <li>Press</li>
                 
                 </ul> -->
          </div>
          <div class="col-md-2 col-6 text-lg-start text-center m-0" v-if="footMenu.includes('privacypolicy')">
            <h6 style="margin-top: 30px">
              <router-link to="/page/privacy-policy" target="_blank" style="color: white; text-decoration: none">{{ $t("footerContents.privacyPolicy") }}</router-link>
            </h6>
            <!-- <h6>More</h6>
                 <ul class="p-0 fs-14">
                   <li>Domestic Flights</li>
                   <li>Investor Realtion</li>
                   <li>Partnership</li>
                   <li>Jobs</li>
                 </ul> -->
          </div>
          <div class="col-md-2 col-6 text-lg-start text-center m-0" v-if="footMenu.includes('termsofuse')">
            <h6 style="margin-top: 30px">
              <router-link to="/page/terms-of-use" target="_blank" style="color: white; text-decoration: none">
                {{ $t("footerContents.termsofUse") }}
              </router-link>
            </h6>
            <!-- <ul class="p-0 fs-14">
                   <li>Privacy policy</li>
                   <li>Terms of use</li>
                   <li>Accessibility</li>
                 </ul> -->
          </div>
        </div>
  
      </div>
      </div>
  
      <div class="container" :class="!portAddress1 && !portAddress2 && !portLegaName ? 'd-none' : ''">
        <div class="row m-0 py-2 d-flex justify-space-evenly flex-row align-center">
            <div class="col-md-12 col-sm-6 col-lg-6 col-12 text-lg-start text-center visible" :class="portAddress1 && portAddress2 ? 'visible' : 'invisible'">
              <p class="m-0" style="font-size:12px;"><span>{{ portAddress1 }}</span> <br />
                <span>Email: {{ portAddress2 }}</span>
              </p>
            </div>
            
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center text-lg-end">
            <p class="m-0" style="font-size: 14px;">{{ $t("footerContents.copyright") }}©{{ get_year() }}, {{ portLegaName }}. {{ $t("footerContents.allrights") }}.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  
  <script>
  
  import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
  
  
  
  export default {
    data() {
      return {
  
        portalLogo: "",
        getInstagram: "",
        getFacebook: "",
        getThreads:"",
        getLinkedIn:"",
        getYoutube:"",
  
        footerMenus: [],
        footMenu: "",
  
        phonePortal: '',
        emailPortal: '',
  
        portAddress1:"",
        portAddress2:"",
        portLegaName:"",
        social_media:[],
  
      }
    },
  
    methods: {
      get_year(){
        return new Date().getFullYear()
      },
  
      getConfig() {
  
        const getConfigData = getConfigDataFromLocalStorage();
  
        if (getConfigData) {
          this.portalLogo = getConfigData.payload.portal_configuration.logo_path

          let social_icons = getConfigData.payload.portal_configuration.social_icons
          if(social_icons){
            this.social_media = social_icons;
          }

  
          this.portAddress1 = getConfigData.payload.portal_configuration.portal_footer_address1
          this.portAddress2 = getConfigData.payload.portal_configuration.portal_footer_address2
          this.portLegaName = getConfigData.payload.portal_configuration.portal_legal_name
  
  
          this.phonePortal = getConfigData.payload.portal_configuration.phone
          this.emailPortal = getConfigData.payload.portal_configuration.email
  
          // let footMenu=""
          this.footMenu = getConfigData.payload.portal_configuration.menus.enabled.footer
          // this.footerMenus = footMenu.split(',')

          this.portAddress2 = this.portAddress2.split(":")[1]
  
          // console.log(this.footMenu, 'fmfmfmfmfm...')
        }
      },
  
  
    },
  
  
    mounted() {
      // this.getConfig();
    },
  
    created() {
      this.getConfig();
      this.get_year();
    }
  }
  
  </script>
  
  
  
  
  
  
  
  
  
  <style scoped>
  /* footer */
  .social-icons {
    color: var(--primary-color) !important;
    padding: 0;
  }
  
  .fs-14 {
    font-size: 14px;
    list-style-type: none;
  }
  
  .fs-14 li {
    padding: 6px;
  }
  
  section {
    padding: 10px 5px;
  }
  
  .slide-card {
    border-radius: 10%;
  }
  
  .card-test {
    position: relative;
    padding: 10px 15px;
    border-radius: 8%;
    max-height: 500px;
  }
  
  .card-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  .radio {
    background-color: #e2e7ff;
    border-radius: 14px;
    color: #007bff;
    width: 120px;
    font-size: 15px !important;
    font-weight: 400px;
  }
  
  .normal-radio {
    font-size: 14px !important;
    font-weight: 400px;
    height: 30px;
    width: 130px;
  }
  
  .cart-adult {
    padding: 2px 15px 15px 15px;
    width: 450px;
    cursor: pointer;
  }
  
  .adult {
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  
  .adult-plus {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #05386d;
  }
  
  .adult-title {
    font-size: 12px;
    font-weight: 600;
    color: #05386d;
  }
  
  .p-datepicker-group-container {
    background-color: #ffffff !important;
    width: 200px;
    padding: 5px 3px;
    border: 1px solid lightgray;
    position: relative;
    right: 14px;
  }
  
  .calendar-container {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    margin-right: 10px;
  }
  
  .calendar-wrapper {
    border-bottom: 1px solid #a9a1a1;
    max-width: 500px;
  }
  .logo{
    filter:grayscale(100%) invert(100%) brightness(2);
    width:250px;
    height:50px;
  }
  @media only screen and (max-width: 992px) {
    .car-right {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
  
    .session-bg {
      background-image: none;
    }
  
    .back {
      background-image: none;
    }
  
    .flight-right {
      width: 80% !important;
    }
  
    .bannar-bg {
      background: none;
    }
  
    .form-btn {
      padding: 10px 0px 0px 0px !important;
      /* color: white; */
    }
  
    .btn-box,
    .pipe {
      color: blueviolet !important;
    }
  
    .btn-active {
      background-color: blueviolet;
      color: white !important;
    }
  }
  
  .mdi-airplane-takeoff::before {
    content: "\F05D5";
    margin-top: 10px;
  }
  
  .mdi-airplane-landing::before {
    content: "\F05D4";
    margin-top: 10px;
  }
  
  @media only screen and (max-width: 1200px) {
    .owl-theme .owl-nav [class*="owl-"] {
      margin-right: 0 !important;
    }
  
    .owl-theme .item {
      width: auto;
      padding-right: 15px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .owl-theme .owl-nav [class*="owl-"] {
      margin-right: 0 !important;
    }
  
    .owl-theme .item {
      width: auto;
    }
  }
  </style>
  